import DOMPurify from 'isomorphic-dompurify';
import type { Element as ElementType, Page } from '../types/model';
import type { ElementTypes, QuestionTypes } from '@shared/types/options';

export function purify(value: any) {
  return DOMPurify.sanitize(value, { USE_PROFILES: { html: true }, ADD_ATTR: ['style', 'target'] });
}

export function getNestedObject(main: Record<string, any>, fullPath: string) {
  const pathList = fullPath.split('.');
  let temp = main as any;
  let lastKey = '';

  pathList.forEach((path) => {
    const pattern = /(.*?)\[(.*?)\]$/g;
    if (pattern.test(path)) {
      const [key, index] = path.replace(pattern, '$1|$2').split('|');
      if (isNaN(parseFloat(index))) {
        temp = temp?.[key]?.find((item: any) => item.id === index);
      } else {
        temp = temp?.[key]?.[index];
      }
    } else {
      const val = temp?.[path];

      if (typeof val === 'object' && val !== null && !Array.isArray(val)) {
        temp = temp?.[path];
      } else if (typeof val !== 'object') {
        lastKey = path;
      }
    }
  });

  return [temp, lastKey];
}

export function setPageLocalizations(page: Page, locale: Record<string, any>) {
  Object.entries(locale).forEach(([fullPath, value]) => {
    const result = getNestedObject(page, fullPath);
    let refObj = result[0];
    const lastKey = result[1];

    if (!refObj) return;

    if (lastKey) {
      /*
        Necessary process for conditionalValue
        conditionalValue items can contain HTML string so when purifying JSON with HTML string,
        JSON.parse method cannot parse, throw error and block the app
      */
      if (lastKey === 'conditionalValue') {
        const obj = JSON.parse(value);

        // Purify only values
        for (const key of Object.keys(obj)) {
          obj[key].value = purify(obj[key].value);
        }

        // Convert purified object to JSON string safely
        value = JSON.stringify(obj);
      } else {
        value = purify(value);
      }

      refObj[lastKey] = value;
    } else {
      refObj = purify(value);
    }
  });

  return page;
}

export function getElementsBySlot(elements: ElementType<ElementTypes>[], slotName?: string) {
  return elements.filter((el) => {
    if (!slotName || slotName === 'default') return !el.slotName || el.slotName === 'default';
    return el.slotName === slotName;
  });
}

export function getElementByType(
  elements: ElementType<ElementTypes>[],
  typeName: ElementTypes | boolean | ((type: string) => boolean)
): null | ElementType<ElementTypes> {
  let found: null | ElementType<ElementTypes> = null;

  for (const el of elements) {
    const condition =
      typeof typeName === 'function'
        ? typeName(el.type)
        : typeof typeName === 'boolean'
        ? typeName
        : el.type === typeName;

    if (condition) {
      found = el;
      break;
    }

    found = getElementByType(el.elements, typeName);
    if (found) break;
  }

  return found;
}

export function getQuestionElement(elements: ElementType<ElementTypes>[]): null | ElementType<QuestionTypes> {
  return getElementByType(elements, (type: string) =>
    /((image)?selection|(date|time)entry|locationpicker|singlerowtext|file|(numeric|weight|height)input)/g.test(type)
  ) as ElementType<QuestionTypes>;
}

export function getPaywallElement(elements: ElementType<ElementTypes>[]): null | ElementType<'plan-selection'> {
  return getElementByType(elements, (type: string) => /(plan-selection)/g.test(type)) as ElementType<'plan-selection'>;
}

export function prepareFontUrl(fonts: string[], url?: string) {
  url = url || `https://fonts.googleapis.com/css2?display=swap`
  const unique = [...new Set(fonts.filter(Boolean).map((item) => item.split(',').map((val) => val.trim().replace(/("|')/g, ''))).flat())]
    .filter((font) => !/^sans(-serif)?$/g.test(font))
  const qString = unique?.map((font) => `family=${font.replace(/\s/g, '+')}:wght@400;500`).join('&');
  return {
    url,
    query: qString,
    full: url + (qString ? `&${qString}` : '')
  }
}

export function filterElementsByConditionalRefs(elements: ElementType<ElementTypes>[], hiddenConditionalRefs: string[]) {
  return elements.filter(element => !(hiddenConditionalRefs || []).includes(element.conditionalRef || ''));
}
